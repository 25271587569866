import config from 'devextreme/core/config';
import { licenseKey } from '../devextreme-license';

config({ licenseKey });

if (getProduct() === 'wl') {
  window.wl = {
    applicationLink: getApplicationLink(),
  };
}

import('./bootstrap');
